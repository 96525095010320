export default {
  props: {
    title: {
      type: String,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    footer: {
      type: Object,
      required: true,
    },
  },
}
